import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { OAuth } from '@nswdoe/doe-ui-core';
// import OAuth from '@/plugins/oauth/oauth';
import env from './plugins/env';
import {getConfig} from '@/utils/config';
import {date, timeDate} from "@/filters/date";
import capitalise from "@/filters/capitalise";

// register focus directive globally
import '@/directives/adsFocus';

Vue.config.productionTip = false;

// Global event bus
Vue.prototype.$eventHub = new Vue();

// fetch env vars from server
Vue.use(env);
if (process.env.NODE_ENV === 'production') {
  Vue.prototype.$env.init('/envmetadata')
  .then(function() {
    initApp();
  });
} else {
  initApp();
}

function initApp() {
  const clientId = getConfig('VUE_APP_OAUTH_CLIENT_ID');
  const scope = `openid ${clientId}/.default`;
  const authOptions = {
    router: router,
    pkce: true,
    secureApp: true,
    authoriseUri: getConfig('VUE_APP_OAUTH_URL'),
    tokenUri: getConfig('VUE_APP_TOKEN_URI'),
    logoutUri: getConfig('VUE_APP_OAUTH_LOGOUT_URL'),
    forceProdMode: true,
    params: {
      response_type: 'code',
      client_id: clientId,
      redirect_uri: encodeURIComponent(getConfig('VUE_APP_OAUTH_REDIRECT_URL')),
      scope: encodeURIComponent(scope)
    }
  };
  Vue.use(OAuth, authOptions);
  Vue.filter('date', date);
  Vue.filter('timeDate', timeDate);
  Vue.filter('capitalise', capitalise);

  new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
  }).$mount('#app')
}
