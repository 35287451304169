import Vue from 'vue';
import applicationsApi from "api/applications";

const determineAccessLevel = (tasks) => {
  if (tasks.every(t => !t.hasAccess)) {
    return "NONE";
  }

  if (tasks.every(t => t.hasAccess)) {
    return "FULL";
  }

  return "PARTIAL";
}

const applicationsModule = {
  state: {
    applicationsBySchool: {},
    roleBySchool: {},
    defaultComment: ""
  },
  getters: {
    applicationsBySchool: state => (schoolId) =>  {
      return state.applicationsBySchool[schoolId] || [];
    },
    applicationsFilter: state => state.applicationsFilter,
    roleBySchool: state => (schoolId) => {
      return state.roleBySchool[schoolId] || "";
    },
    defaultComment: state => state.defaultComment
  },
  mutations: {
    setApplications(state, {applications, schoolId}) {
      const appsWithAccessLevels = 
        applications.map(app => {
          app.accessLevel = determineAccessLevel(app.tasks);
          return app;
        });

      Vue.set(state.applicationsBySchool, schoolId, appsWithAccessLevels);
    },
    setRole(state, {role, schoolId}) {
      state.roleBySchool[schoolId] = role;
    },
    setDefaultComment(state, comment) {
      state.defaultComment = comment;
    }
  },
  actions: {
    async fetchApplications({ commit }, { schoolId }) {
      commit("setIsLoading", true);

      return await applicationsApi
        .fetchApplications(schoolId)
        .then(response => {
          commit("setApplications", {applications: response.appsList, schoolId: schoolId});
          commit("setRole", {role: response.role, schoolId: schoolId});
        })
        .finally(() => {
          commit("setIsLoading", false);
        });
    },
    async sendAccessRequest({commit, state}, { selectedTasks, schoolId, schoolName, appId, comment }) {
      commit("setIsLoading", true);

      await applicationsApi.postAccessRequest(schoolId, schoolName, appId, selectedTasks, comment)
      .then(() => {
        const app = state.applicationsBySchool[schoolId].find(a => a.applicationId === appId);
        for (const t of app.tasks) {
          t.accessRequested = t.accessRequested || selectedTasks.some(rt => rt === t.taskId);
        }

        commit("showSnackbar", {type: "success", text: "Your access request has been sent", show: true});
      })
      .finally(() => {
        commit("setIsLoading", false);
      });
    }
  }
};

export default applicationsModule;
