import Vue from "vue";
import VueRouter from "vue-router";
import Welcome from "../views/Welcome";
import Roles from "../views/Roles";
import MyAccess from "../views/MyAccess";
import History from "../views/History";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    meta: {
      title: "Welcome",
    }
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    meta: {
      title: "Select Role",
    }
  },
  {
    path: '/access',
    name: 'My Access',
    component: MyAccess,
    meta: {
      title: "My Access",
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.selectedSchoolId == null) {
        next("/");
        return;
      }
      next();
    }
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta: {
      title: "History"
    }
  },

  { path: '*', component: () => import(/* webpackChunkName: 'info-views'*/'@/views/NotFound.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  document.title = "MMA" + (title ? ' - ' + title : '');
  next();
});

export default router
