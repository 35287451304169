import axios from 'axios';
import Vue from 'vue';
import store from '../store'
import { getApiUrl } from '@/utils/config';
import { buildError } from '@/utils'

const fetchApplications = (schoolId) => {
  if (schoolId == null) {
    return [];
  }

  return axios.get(`${getApiUrl()}/UAMS/ProfileTemplate/applications/${schoolId}`,
    {
      headers: Vue.prototype.$OAuth.buildHttpHeader()
    })
    .then((response) => {
      const appsIconMapped = response.data.applications.map((app) => Object.assign(app, { icon: window.essentialIconCodesMap ? window.essentialIconCodesMap[app.applicationCode] || 'my-generic-blank' : 'my-generic-blank' }));
      return {appsList: appsIconMapped, role: response.data.roleCode};
    })
    .catch((err) => {
      store.dispatch("handleApiError", err);
      throw err;
    });
};

const postAccessRequest = (schoolId, schoolName, appId, taskIds, comments) => {
  if (schoolId == null || appId == null) {
    const errParams = buildError("Missing params", `The access request was missing some parameters. schoolId: ${schoolId}, appId: ${appId}, taskIds: ${taskIds}`)
    return Promise.reject(errParams)
      .catch(errParams => {
        store.dispatch("handleApiError", errParams);
      });
  }

  const data = {
    locationID: schoolId,
    locationName: schoolName,
    enabledTasks: taskIds,
    requestorComments: comments || ""
  }
  
  return axios.put(`${getApiUrl()}/UAMS/request/application/${appId}`,
    data,
    {
      headers: Vue.prototype.$OAuth.buildHttpHeader()
    })
    .then((response) => response.data)
    .catch((err) => {
      store.dispatch("handleApiError", err);
    });
}

export default { fetchApplications, postAccessRequest }
