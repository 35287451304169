<template>
  <v-dialog
    v-model="valueInternal"
    :width="width"
    @input="handleReturn"
    id="app--dialog"
  >
    <v-card
      role="alertdialog"
      id="alert-dialog"
    >
      <slot name="content">
        <v-card-title class="title">
          <v-container fluid>
            <div
              class="d-flex align-center"
              v-if="type"
            >
              <div class="mr-2">
                <v-icon
                  v-if="type === 'success'"
                  class="green--text"
                  large
                >
                  done
                </v-icon>
                <v-icon
                  v-if="type === 'warning'"
                  class="red--text text--accent-4"
                  large
                >
                  highlight_off
                </v-icon>
                <v-icon
                  v-if="type === 'error'"
                  class="red--text"
                  large
                >
                  warning
                </v-icon>
              </div>
              <h1 class="title">
                {{ title }}
              </h1>
            </div>
            <div v-if="!type">
              <h1 class="title">
                {{ title }}
              </h1>
            </div>
          </v-container>
        </v-card-title>

        <slot name="cardText">
          <v-card-text class="subheading pt-0 pb-4">
            <v-container fluid>
              <span class="msa-text-grey">{{ message }}</span>
            </v-container>
          </v-card-text>
        </slot>

        <slot name="cardActions">
          <v-card-actions>
            <v-spacer />
            <div
              v-for="(action, i) in actionsList"
              :key="i"
              class="mr-3"
            >
              <v-btn
                :id="`alert-dialog--action-${i}`"
                :outlined="action.type === 'secondary'"
                :text="action.type === 'tertiary'"
                :class="{['btn-type--' + action.type]: true, 'primary': action.type === 'primary'}"
                @click="handleReturn(action.name)"
                :aria-label="action.name"
              >
                {{ action.name }}
              </v-btn>
            </div>
          </v-card-actions>
        </slot>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    message: {
      type: [String, Error],
      default: null
    },
    callback: {
      type: Function,
      default: null
    },
    actions: {
      type: [String,Array],
      default: null
    },
    type: {
      type: String,
      default: null
    },
    value: {
      type: Boolean
    },
    width: {
      type: String,
      default: "420px"
    }
  },
  data() {
    return {
      actionsList: [],
      autoClose: false,
      valueInternal: this.value
    };
  },
  created() {
    if (typeof this.actions === "string") {
      const actionsList = this.actions && this.actions.length ? this.actions.split("|") : ["ok"];
      this.actionsList = actionsList.map(aName => { return { name: aName, type: 'tertiary' }; })
    } else {
      this.actionsList = this.actions;
    }
  },
  watch: {
    value: function(val) {
      // manually bind to the externally modifiable value property
      this.valueInternal = val;
      if (val) {
        this.focusOnOpen()
      }
    }
  },
  methods: {
    open() {
      this.valueInternal = true;
      this.autoClose = true;
      this.focusOnOpen();
    },
    focusOnOpen() {
      // focus on the primary action control.
      if (this.actionsList.length) {
        const primaryIndex = this.actionsList.findIndex(a => a.primary);
        const index = primaryIndex === -1 ? 0 : primaryIndex;
        setTimeout(() => {
          const el = document.getElementById('alert-dialog--action-' + index);
          if (el) {
            el.focus();
          }
        }, 350);
      }
    },
    handleReturn(result) {
      if (this.callback) {
        this.callback(result);
      }
      if (this.autoClose) {
        // only close if it was manually opened
        this.valueInternal = false;
      }
    }
  }
};
</script>

<style scoped>
div.v-card {
  padding-top: 5px;
  padding-left: 5px;
}
div.container {
  padding: 0px;
}
i {
  margin-top: 5px;
}
.btn-type--primary {
  background: #041E42;
  color: #FFFFFF;
}

.btn-type--secondary {
  background: #FFFFFF;
  color: #041E42;
}

.btn-type--tertiary {
  background: rgba(255, 255, 255, 0);
  color: #041E42;
}

</style>
