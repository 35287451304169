<template>
  <div
    class="essential"
    :style="{
      width: size,
      height: size,
      border: this.transparent ? '1px solid white' : 'none'
    }"
  >
    <svg
      :class="'icon ' + getIconClass(appCode)"
      :style="getDimensions(true)"
      :id="'svg-' + svgId"
      viewBox="0 0 58 58"
    >
      <use :xlink:href="'#' + appCode" />
    </svg>
  </div>
</template>

<script>
const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export default {
  name: "AppIcon",
  props: {
    appCode: {
      type: String,
      default: null
    },
    transparent: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "40px"
    }
  },
  data() {
    return {
      svgId: null,
      isInternetExplorer: document.documentMode != null
    };
  },
  created() {
    this.svgId = this._uid;
  },
  mounted() {
    if (this.isInternetExplorer) {
      this.renderIconForIe();
    }
  },
  methods: {
    getIconClass(id) {
      if (!this.transparent) {
        // iconToClass function is included from essentialIconsMap.js on index.html
        // eslint-disable-next-line
        return iconToClass[id] || "essentials-default";
      }
    },
    getDimensions() {
      const dim = this.size
        ? (this.transparent ? parseInt(this.size) - 2 : this.size)
        : "40px";
      return { width: dim, height: dim };
    },
    copyElement(original, newTagName) {
      // Create a replacement tag of the desired type
      var copiedElement = document.createElement(newTagName);

      // Grab all of the original's attributes, and pass them to the copiedElement
      if (original && original.attributes) {
        for (var i = 0; i < original.attributes.length; ++i) {
          var nodeName = original.attributes.item(i).nodeName;
          var nodeValue = original.attributes.item(i).nodeValue;

          copiedElement.setAttribute(nodeName, nodeValue);
        }

        // Persist contents
        for (var c = 0; c < original.childNodes.length; c++) {
          copiedElement.appendChild(original.childNodes[c].cloneNode(true));
        }
      }

      return copiedElement;
    },
    renderIconForIe: async function() {
      let knownIcon = document.getElementById("my-generic-blank");
      let retryCount = 0;
      while (!knownIcon && retryCount < 8) {
        // try and wait for icon svg div to load if not already available
        await sleep(1000);
        knownIcon = document.getElementById("my-generic-blank");
        retryCount++;
      }
      if (!knownIcon) {
        // eslint-disable-next-line no-console
        console.error("Could not find DxP Icons on page");
        return;
      }

      var appName = this.appCode;
      var svg = this.copyElement(document.getElementById(appName), "div");
      const mySvg = document.getElementById("svg-" + this.svgId);
      mySvg.removeChild(mySvg.childNodes[0]);
      for (var i = 0; i < svg.childNodes.length; i++) {
        mySvg.appendChild(svg.childNodes[i].cloneNode(true));
      }
    }
  }
};
</script>

<style scoped>
.essential,
svg {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 0.2rem;
}
</style>
