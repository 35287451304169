const searchFilter = (sourceStrs, searchStr) => {
  // check that all searchStr words 'starts with' in at least one of the source strings
  // e.g. searching 'ab de' should match a record with values ['abc', 'def']
  const sourceStrings = sourceStrs.filter((s) => s != null);
  const searchStrings = searchStr.split(' ').filter((f) => f !== '');
  return searchStrings.every((ss) => {
    return sourceStrings.findIndex((source) => source.toLowerCase().split(' ').some((s) => s.startsWith(ss.toLowerCase()))) !== -1;
  });
}

const searchFilterContains = (sourceStrs, searchStr) => {
  // check that all searchStr words is contained in at least one of the source strings
  // e.g. searching 'ab de' should match a record with values ['zabc', 'zdef'] or ['de-ab', 'blah']
  const sourceStrings = sourceStrs.filter((s) => s != null);
  const searchStrings = searchStr.split(' ').filter((f) => f !== '');
  return searchStrings.every((ss) => {
    return sourceStrings.findIndex((source) => source.toLowerCase().indexOf(ss.toLowerCase()) !== -1) !== -1;
  });
}

import { debounce } from ".";

// needs to be merged in with a component's methods to inherit 'this' as the vue instance.
// e.g. methods: {...searchDebounce, otherMethod() {}}
const searchDebounce = {
  onSearchChange(value) {
    const meta = this.$router.currentRoute.meta;
    const mutator = meta && meta.searchMutator;

    if (mutator) {
      this.$store.commit(mutator, value);
    }
  },
  onSearchChangeDebounced: debounce(function (v) {this.onSearchChange(v)}, 300)
}

export {searchFilter, searchFilterContains, searchDebounce};
