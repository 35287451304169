<template>
  <v-card
    class="py-9 px-3 px-md-12 pending-request-error--card"
    :flat="$vuetify.breakpoint.smAndDown"
  >
    <div class="d-flex flex-column align-center pending-request-items-container">
      <div class="d-flex justify-center pb-12">
        <CircleIcon
          icon="mdi-alert-outline"
          size="185"
          background-color="#C8DCF0"
          color="#041E42"
        />
      </div>
      <div class="mb-7">
        <div class="headline font-weight-bold">
          Your access request is under review
        </div>
      </div>
      <div class="mb-6">
        <div class="text-center">
          You have pending requests for <b>{{ requestName }}</b> access to this school. Your application is still under review.
        </div>
      </div>
      <div 
        v-if="canGoToApps"
        class="mb-3 mt-2"
      >
        <v-btn 
          @click="$router.push('/access')"
          class="primary"
        >
          Review requested apps
        </v-btn>
      </div>
      <div
        v-else
        class="mb-3" 
      >
        <div>
          Please try again later.
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import CircleIcon from "@/components/CircleIcon";

export default {
  name: "PendingRequestError",
  props: {
    requestName: {
      type: String,
      default: "application"
    },
    canGoToApps: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CircleIcon
  }
}
</script>

<style scoped>
/* IE11 overflow FIX */
.pending-request-items-container > div {
  max-width: 100%;
}
</style>