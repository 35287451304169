<template>
  <v-card
    id="app-task-list--card"
    :flat="$vuetify.breakpoint.smAndDown"
    :tile="$vuetify.breakpoint.smAndDown"
    role="dialog"
    aria-labelledby="request-access-dialog--header"
    aria-describedby="request-access-dialog--description"
    v-ads-trap-focus
    v-ads-set-focus:request-access-dialog--access-permissions-title
  >
    <div class="px-6 py-3 py-md-6">
      <div class="d-flex flex-column mb-4">
        <div class="d-flex align-center">
          <div
            shrink
            class="mt-1 mr-4"
          >
            <CircleIcon
              :outlined="true"
            >
              <template slot="icon">
                <img
                  width="30px"
                  height="30px"
                  src="../assets/touchApp.svg"
                  alt="touch app icon"
                >
              </template>
            </CircleIcon>
          </div>
          <h3 id="request-access-dialog--header">
            Request access
          </h3>
          <div
            class="d-flex"
            style="flex: 1; justify-content: flex-end"
          >
            <v-btn
              icon
              left
              @click="$emit('close')"
              aria-label="Close Request access dialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <p id="request-access-dialog--description">
        Choose your requested permissions, add any comments you would like to make, then select Send Request.
      </p>
      <v-card
        class="px-6 py-3 d-banner"
      >
        <div class="d-flex align-center">
          <AppIcon :app-code="appIcon" />
          <div class="ml-3 subtitle-1">
            {{ appDesc }}
          </div>
        </div>
      </v-card>
      <div class="pt-6">
        <h3
          id="request-access-dialog--access-permissions-title"
          class="subtitle-1"
          tabindex="0"
          :aria-label="`Access permissions for ${appDesc}`"
        >
          Access permissions
        </h3>
        <p
          class="caption ads-dark-60"
          v-if="isLoading && !isDirty"
          style="height:100px"
        >
          Loading your tasks...
        </p>
        <v-row>
          <v-col class="pt-0 pr-0">
            <v-checkbox
              v-for="t in firstHalfTasks"
              :id="`request-access-dialog--task-${t.taskId}`"
              :key="t.taskId"
              v-model="selectedTasks"
              :value="t.taskId"
              :label="t.taskName + (isTaskRequested(t.taskId) ? ' (request pending)' : '')"
              :indeterminate="isTaskRequested(t.taskId)"
              :disabled="isTaskDisabled(t.taskId)"
              dense
              hide-details="true"
            />
          </v-col>
          <v-col class="pt-0">
            <v-checkbox
              v-for="t in secondHalfTasks"
              :key="t.taskId"
              v-model="selectedTasks"
              :value="t.taskId"
              :label="t.taskName + (isTaskRequested(t.taskId) ? ' (request pending)' : '')"
              :indeterminate="isTaskRequested(t.taskId)"
              :disabled="isTaskDisabled(t.taskId)"
              dense
              hide-details="true"
            />
          </v-col>
        </v-row>
      </div>
      <div class="pt-6">
        <v-form
          ref="form"
          v-model="isValid"
        >
          <v-textarea
            v-model="comment"
            outlined
            name="access-request-comment"
            placeholder=" "
            maxlength="255"
            counter="255"
            label="Add a comment (optional)"
            :rules="[rules.maxLength]"
          />
        </v-form>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          @click="sendAccessRequest"
          :disabled="!isDirty || !isValid || !fnActionByName('REQUEST_APPLICATION')"
          class="primary"
        >
          <v-icon class="mr-3">
            mdi-send-outline
          </v-icon>
          Send request
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import CircleIcon from "@/components/CircleIcon";
import AppIcon from "@/components/AppIcon";
import { mapGetters } from "vuex";

export default {
  name: "AppTaskList",
  props: {
    appId: {
      type: Number,
      default: 0
    },
    appIcon: {
      type: String,
      default: ""
    },
    appDesc: {
      type: String,
      default: ""
    },
    tasks: {
      type: Array,
      default: () => []
    },
    defaultComment: {
      type: String,
      default: ""
    }
  },
  components: {
    CircleIcon,
    AppIcon
  },
  computed: {
    ...mapGetters({
      selectedSchoolId: "selectedSchoolId",
      selectedSchoolName: "selectedSchoolName",
      isLoading: 'isLoading',
      fnActionByName: 'getActionByName'
    }),
    firstHalfTasks() {
      const midpoint = Math.ceil(this.tasks.length / 2);
      return this.tasks.slice(0, midpoint);
    },
    secondHalfTasks() {
      const midpoint = Math.ceil(this.tasks.length / 2);
      return this.tasks.slice(midpoint);
    },
    isDirty() {
      return this.selectedTasks.length !== this.originalTasks.length ||
             this.selectedTasks.some(t => this.originalTasks.indexOf(t) === -1);
    }
  },
  data() {
    const selectedTasks = this.tasks.reduce((a, t) => {
      if (t.hasAccess) {
        a.push(t.taskId);
      }
      return a;
    }, []);

    const originalTasks = [...selectedTasks];

    const grantedTasks = this.tasks.reduce((a, t) => {
      if (t.birthright || t.hasAccess) {
        a.push(t.taskId);
      }
      return a;
    }, []);

    const alreadyRequestedTasks = this.tasks.reduce((a, t) => {
      if (t.accessRequested) {
        a.push(t.taskId)
      }
      return a;
    }, []);

    return {
      selectedTasks: selectedTasks,
      originalTasks: originalTasks,
      grantedTasks: grantedTasks,
      alreadyRequestedTasks: alreadyRequestedTasks,
      comment: this.defaultComment,
      isValid: true,
      rules: {
        maxLength: v => v.length <= 255 || "Max length 255 characters"
      }
    }
  },
  methods: {
    isTaskRequested(taskId) {
      return this.alreadyRequestedTasks.indexOf(taskId) !== -1;
    },
    isTaskDisabled(taskId) {
      return this.grantedTasks.indexOf(taskId) !== -1 || this.isTaskRequested(taskId);
    },
    async sendAccessRequest() {
      if (this.$refs.form.validate()) {
        const newSelectedTasks = this.selectedTasks.filter(tId => this.grantedTasks.indexOf(tId) === -1);

        const data = {
          selectedTasks: newSelectedTasks,
          schoolId: this.selectedSchoolId,
          schoolName: this.selectedSchoolName,
          appId: this.appId,
          comment: this.comment
        };

        this.$store.dispatch('sendAccessRequest', data)
        .then(() => {
          this.$store.dispatch('fetchHistory');
          this.$emit("close");
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .d-banner {
    background-color: #ECEFF1;
}
  .theme--dark.d-banner {

      background-color: #263238;}
}

</style>
