import Vue from "vue";

const errorModule = {
  state: {
    error: {}
  },
  getters: {
    error: state => state.error,
  },
  mutations: {
    setError(state, {show, title, code, message, callback}) {
      if (show) {
        state.error = {
          show,
          title: title || "Error",
          code,
          message,
          callback
        };
        return;
      }
      
      Vue.set(state.error, 'show', false);

      // remove code and message once dialog has animated away
      setTimeout(() => {
        state.error.title = '';
        state.error.code = '';
        state.error.message = '';
      }, 300);
    }
  },
  actions: {
    handleApiError({commit}, err) {
      const response = err.response || err;

      if (!response) {
        commit('setError', {show: true, code: -1, message: 'An unspecified error occured.'});
        throw 'An unspecified error occured.';
      }

      if (response.data && response.data.errorCode === "MSA-110") {
        // token expired
        commit('setError', {show: true, code: response.data.errorCode, message: 'Session expired.', callback: function() { Vue.prototype.$OAuth.getAuth() }});
      }

      if (response.data) {
        const errCode = response.data.errorCode || response.data.error;
        const errMessage = response.data.errorMessage || response.data.message;
        commit('setError', {show: true, code: errCode, message: errMessage});
      } else {
        commit('setError', {show: true, code: "", message: response});
      }
      
      // eslint-disable-next-line no-console
      console.error(response);
      throw response;

    }
  }
};

export default errorModule;
