import schoolsApi from 'api/schools';

const schoolsModule = {
  state: {
    userSchools: [],
    otherSchools: [],
    selectedSchool: {},
    actions: []
  },
  getters: {
    userSchools: state => state.userSchools,
    otherSchools: state => state.otherSchools,
    actions: state => state.actions,
    selectedSchool: state => state.selectedSchool,
    selectedSchoolName: state => (state.selectedSchool && state.selectedSchool.name) || "School",
    selectedSchoolId: state => state.selectedSchool && state.selectedSchool.id,
    isUserSchoolSelected: state => state.selectedSchool.id != null && state.userSchools.some(s =>  s.id === state.selectedSchool.id),
    getActionByName: state => actionName => 
       state.actions.find(action => action === actionName)

  },
  mutations: {
    setSelectedSchool(state, schoolId) {
      state.selectedSchool = state.userSchools.find(s => s.id === schoolId) ||
                             state.otherSchools.find(s => s.id === schoolId);
    },
    setSchools(state, schools) {
      state.userSchools = schools.userLocations;
      state.otherSchools = schools.otherLocations.map(s => {return {...s, isOtherSchool: true}});
    },
    setActons(state, actions) {
      state.actions = actions;
    }
  },
  actions: {
    async fetchSchools({commit}) {
      commit("setIsLoading", true);

      return await schoolsApi.fetchSchools()
        .then((response) => {
          commit("setSchools", response);
          commit('setActons', response.mmaPermissions);
          
        })
        .finally(() => {
          commit("setIsLoading", false);
        });
    },
    resetToDefaultSchool({commit, state}) {
      const localStoreLastSchoolId = parseInt(localStorage.getItem("defaultSchoolId"));
      let defaultSchoolId = null;

      if (state.userSchools && state.userSchools.length) {
        // check that the last school id is in this current list of locations
        defaultSchoolId = state.userSchools.some(s => s.id === localStoreLastSchoolId) ? localStoreLastSchoolId : state.userSchools[0].id;
      }

      commit("setSelectedSchool", defaultSchoolId);

      return defaultSchoolId;
    }
  }
};

export default schoolsModule;