<template>
  <v-container class="pa-0">
    <v-row class="pa-md-12">
      <v-col class="pt-0">
        <v-card
          v-if="!hasHistory"
          id="history--card"
          class="mx-3 mt-3 center-content"
        >
          <div class="pl-4 pt-4 pb-2">
            <p>{{ isLoading ? "Loading..." : "No history found." }}</p>
          </div>
        </v-card>
        <div
          v-if="hasHistory && fnActionByName('LIST_REQUEST_HISTORY')"
          id="history--card-expansion-list"
        >
          <v-expansion-panels
            accordion
            :flat="$vuetify.breakpoint.smAndDown"
            v-model="expandedIndex"
          >
            <v-expansion-panel
              v-for="(item,i) in requestHistory"
              :key="i"
            >
              <v-expansion-panel-header
                :aria-expanded="expandedIndex === i ? 'true' : 'false'"
              >
                <div class="d-flex align-center">
                  <div
                    class="mr-2"
                    style="min-width: 110px"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    {{ item.requestedTimestamp | date }}
                  </div>
                  <AppIcon
                    v-if="item.isApp"
                    :app-name="item.application.icon"
                  />
                  <AppIconRole
                    v-if="!item.isApp"
                    :role-code="item.roleCode"
                  />

                  <div class="px-2">
                    {{ item.requestDescription }}
                  </div>

                  <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
                  <div
                    class="pr-6 text-right"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    {{ item.locationDetails ? item.locationDetails.name : "" }}
                  </div>
                  <div
                    class="pr-12"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    <RequestStatusPill :approval-status="item.approvalStatus" />
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- DESKTOP CONTENT -->
                <div
                  class="text-left mt-3 ml-3"
                  v-if="$vuetify.breakpoint.mdAndUp"
                >
                  <v-row>
                    <v-col cols="3">
                      <div class="subtitle-1">
                        Date Actioned
                      </div>
                      <ValueOrDefault :value="formatTimeDate(item.approvalTimestamp)" />

                      <div class="subtitle-1 mt-6">
                        Access Requested
                      </div>
                      <div>
                        {{ item.accessRequested }}
                      </div>
                    </v-col>

                    <v-col cols="6">
                      <div class="subtitle-1">
                        Authoriser Comment
                      </div>
                      <ValueOrDefault :value="item.approverComments" />
                    </v-col>

                    <v-col cols="3">
                      <div class="subtitle-1">
                        Authoriser
                      </div>
                      <ValueOrDefault :value="item.approverFirstName + ' ' + item.approverLastName" />
                    </v-col>
                  </v-row>
                </div>

                <!-- MOBILE CONTENT -->
                <div
                  class="text-left"
                  v-if="$vuetify.breakpoint.smAndDown"
                >
                  <div class="d-flex align-center justify-space-between mt-3">
                    <div class="subtitle-1">
                      School
                    </div>
                    <div class="text-right">
                      {{ item.locationDetails ? item.locationDetails.name : "" }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between mt-4">
                    <div class="subtitle-1">
                      Authoriser
                    </div>
                    <ValueOrDefault :value="item.approverFirstName + ' ' + item.approverLastName" />
                  </div>

                  <div class="d-flex align-center justify-space-between mt-4">
                    <div class="subtitle-1">
                      Result
                    </div>
                    <RequestStatusPill :approval-status="item.approvalStatus" />
                  </div>

                  <div class="d-flex align-center justify-space-between mt-4">
                    <div class="subtitle-1">
                      Date Requested
                    </div>
                    <div>{{ item.requestedTimestamp | date }}</div>
                  </div>

                  <div class="d-flex align-center justify-space-between mt-4">
                    <div class="subtitle-1">
                      Date Actioned
                    </div>
                    <ValueOrDefault :value="formatDate(item.approvalTimestamp)" />
                  </div>

                  <div class="d-flex align-start justify-space-between mt-4">
                    <div class="subtitle-1">
                      Access Requested
                    </div>
                    <div
                      style="max-width: 50%"
                      class="text-right"
                    >
                      {{ item.accessRequested }}
                    </div>
                  </div>

                  <div class="mt-6">
                    <div class="fake-text-area">
                      <label class="subtitle-1">Authoriser Comment</label>
                      <div class="fake-text-area-content">
                        <ValueOrDefault :value="item.approverComments" />
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <v-divider v-if="$vuetify.breakpoint.smAndDown" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { AppIcon } from '@nswdoe/doe-ui-core';
import RequestStatusPill from "@/components/RequestStatusPill";
import AppIconRole from "@/components/AppIconRole";
import ValueOrDefault from  "@/components/ValueOrDefault";
import { timeDate, date } from "@/filters/date";

export default {
  components: {
    AppIcon,
    RequestStatusPill,
    AppIconRole,
    ValueOrDefault
  },
  data() {
    return {
      expandedIndex: null
    }
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      requestHistory: "requestHistory",
      fnActionByName: 'getActionByName'
    }),
    hasHistory() {
      return this.requestHistory && this.requestHistory.length > 0;
    }
  },
  created: function() {
    if (!this.$store.getters.historyInitialised && !this.requestHistory.length) {
      this.$store.dispatch("fetchHistory");
    }
  },
  methods: {
    formatTimeDate(dateStr) {
      return timeDate(dateStr);
    },
    formatDate(dateStr) {
      return date(dateStr);
    }
  }
}
</script>

<style lang="scss" scoped>
  #app.mobile {
    #history--card {
      border: none;
      box-shadow: none;
    }
    #history--card-expansion-list {
      .v-expansion-panel:before {
        box-shadow: none;
      }

    }
  }
  .center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
  }
  #app:not(.mobile) .v-expansion-panel-content {
    background: $ads-light-10;
  }
  .theme--dark {
    &#app:not(.mobile) .v-expansion-panel-content {
      background: $ads-dark-70;
    }
    .fake-text-area {
      label {
        background: $ads-dark-80;
      }
    }
  }

  .fake-text-area {
    border-radius: 4px;
    border: 1px solid $ads-navy;
    label {
      position: relative;
      top: -12px;
      left: 8px;
      padding-left: 3px;
      padding-right: 3px;
      background: $ads-white;
    }
    .fake-text-area-content {
      position: relative;
      top: -4px;
      padding: 0 15px 15px 15px;
    }
  }
</style>
