<template>
  <div 
    class="d-flex align-center justify-center"
    :style="{
      'background-color': backgroundColor,
      'border-radius': '50%', 
      'width': size + 'px',
      'height': size + 'px'
    }"
  >
    <slot name="icon">
      <v-icon
        :class="{'material-icons-outlined': outlined}"
        :size="iconSize"
        :style="{'color': color}"
      >
        {{ icon }}
      </v-icon>
    </slot>
  </div>
</template>

<script>
  export default {
    name: "CircleIcon",
    props: {
      icon: {
        type: String, 
        default: "mdi-alert-outline"
      },
      backgroundColor: {
        type: String,
        default: "#407EC9"
      },
      color: {
        type: String,
        default: "#FFFFFF"
      },
      size: {
        type: String,
        default: "48"
      },
      outlined: {
        type: Boolean
      }
    },
    computed: {
      iconSize: function() {
        return parseInt(this.size) * 0.65;
      }
    }
  }
</script>
