<template>
  <div>
    <v-list class="py-0 list-header">
      <v-list-item class="pl-3 pr-0 px-md-3">
        <v-list-item-content>
          <v-row>
            <v-col class="col-8 col-md-10">
              <div class="subtitle-1 ml-12">
                {{ appHeading }}<slot name="appHeadingSuffix" />
              </div>
            </v-col>
            <v-col class="col-4 col-md-2 pr-0 pr-md-3">
              <div class="d-flex justify-end">
                <div
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="subtitle-1 accessHeading"
                >
                  {{ accessHeading }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-list-item-content>
        <!-- spacing placeholder -->
        <v-list-item-action class="ml-0 ml-md-3">
          <v-btn
            icon
            ripple
            style="visibility: hidden"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-list
      class="pt-0"
      :dense="$vuetify.breakpoint.smAndDown"
    >
      <div
        v-for="app in items"
        :key="app.applicationCode"
        :id="`staff-apps--row-${app.applicationCode}`"
      >
        <v-list-item

          class="pl-3 pr-0 px-md-3"
        >
          <v-list-item-content>
            <v-row class="d-flex align-center">
              <v-col class="col-8 col-md-10">
                <div class="d-flex align-center">
                  <AppIcon
                    :app-code="app.icon"
                    :size="$vuetify.breakpoint.xsOnly ? '25px' : '40px'"
                  />
                  <span class="pl-3 listTitle">{{ app.applicationDesc }}</span>
                </div>
              </v-col>
              <v-col class="col-4 col-md-2">
                <div class="d-flex justify-end">
                  <Pill
                    :label="app.accessLevel || 'NONE'"
                    v-bind="determinePillColour(app.accessLevel)"
                  />
                </div>
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-action class="ml-0 ml-md-3">
            <v-btn
              v-if="app.accessLevel.toUpperCase() !== 'FULL'"
              @click="navigateToApplication(app)"
              icon
              ripple
              :aria-label="'Edit ' + app.applicationDesc + ' access'"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>

            <v-btn
              v-else
              icon
              ripple
              style="visibility: hidden"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import Pill from "@/components/Pill";
import colours from "@/settings/doe-colors";

export default {
  name: "AppAccessList",
  components: {
    AppIcon,
    Pill
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    appHeading: {
      type: String,
      default: ""
    },
    accessHeading: {
      type: String,
      default: "Access"
    }
  },
  methods:{
    navigateToApplication(app) {
      this.$emit("appSelected", app);
    },
    determinePillColour(accessLevel) {
      const colorMap = {
        PARTIAL: { color: colours.blue1, background: colours.blue3Dark },
        FULL: { color: colours.white, background: colours.blue1 },
        PENDING: { color: colours.white, background: colours.warningOrange },
        default: { color: colours.blue1, background: colours.light20 }
      }
      const al = (accessLevel || "").toUpperCase();
      return colorMap[al] || colorMap.default;
    }
  }
}
</script>

<style lang="scss" scoped>
  // match pill width settings for alignment
  #app.mobile .accessHeading {
    width: 100px;
  }
  .accessHeading {
    width: 121px;
    max-width: 100%;
  }
  .list-header {
    background-color: #CFD8DC;
  }
  .theme--dark {
    .list-header {
      background-color: #263238;
    }
  }
  ::v-deep {
    .v-list {
      padding: 0;
    }
    .v-list-item {
      border-bottom: 1px solid rgba(0,0,0,0.3);
    }
    .theme--dark {
      .v-list-item {
        border-bottom: 1px solid #6e6e6e;
      }
    }
  }
</style>
