import Vue from "vue";
import Vuex from "vuex";

import schoolsModule from "./modules/schools";
import applicationsModule from "./modules/applications";
import historyModule from "./modules/history";
import errorModule from "./modules/error";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    schoolsModule,
    applicationsModule,
    historyModule,
    errorModule
  },
  state: {
    isLoading: false,
    loadingCount: 0,
    snackbar: {show: false}
  },
  getters: {
    isLoading: state => state.isLoading,
    snackbar: state => state.snackbar
  },
  mutations: {
    setIsLoading(state, isLoading) {
      if (isLoading) {
        state.loadingCount++;
      } else {
        state.loadingCount--;
      }

      state.isLoading = state.loadingCount > 0;
    },
    showSnackbar(state, {text, type, show, timeout, allowDismiss, icon, htmlText, persistBetweenRoutes}) {
      state.snackbar = {
        timeout: timeout || 5000,
        text,
        type,
        show,
        allowDismiss,
        icon,
        htmlText,
        persistBetweenRoutes
      };

      if (show) {
        // reset the snackbar state
        setTimeout(() => {
          state.snackbar = {
            show: false
          }
        }, timeout || 5000);
      }
    }
  },
  actions: {
  }
});
