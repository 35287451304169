<template>
  <div>
    <span
      class="value-or-default--text" 
      v-if="hasValue(value)"
    >{{ value }}</span>
    <span
      v-else
      class="value-or-default--none-text"
    >{{ defaultText }}</span>
  </div>
</template>
<script>
export default {
  name: "ValueOrDefault",
  props: {
    value: {
      type: String,
      default: ""
    },
    defaultText: {
      type: String,
      default: "-"
    }
  },
  methods: {
    hasValue(v) {
      return  v != null && v.trim() !== '';
    }
  }
}
</script>

<style lang="scss" scoped>
  .value-or-default--text {
    overflow-wrap: anywhere;
  }

  .value-or-default--none-text {
    color: $ads-light-40;
  }
</style>