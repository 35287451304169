import historyApi from "api/history";
import capitalise from '@/filters/capitalise';

const isApp = (item) => {
  return item.application != null;
}

const determineAccessRequested = (item) => {
  if (isApp(item)) {
    return item.tasks.map(t => t.name).join(", ");
  }

  return determineDescription(item);
}

const determineDescription = (item) => {
  if (isApp(item)) {
    return item.application.applicationDesc;
  }

  const roleMap = {
    teacher: "Teacher Access Package",
    sam: "Admin Manager Access Package"
  }

  return roleMap[(item.roleCode || "").toLowerCase()] || "Custom Role";
};

const extractNames = (detUserId) => {
  if (!detUserId || detUserId.trim() === '') {
    return {firstName: '', lastName: ''};
  }
  const names = detUserId.split('.');
  const firstName = capitalise(names[0].replace(/[0-9]/g, ''));
  const lastName = names.length > 1 ? capitalise(names[names.length - 1].replace(/[0-9]/g, '')) : '';
  return {firstName, lastName};
};

const historyModule = {
  state: {
    history: [],
    historyLoading: false,
    historyInitialised: false
  },
  getters: {
    requestHistory: state => state.history,
    pendingSchools: (state) => {
      // approved requests are also pending until they reach 's' (success) status.
      const pendingStatuses = ['p', 'e', 'a'];
      return state.history
        .filter(h => pendingStatuses.indexOf(h.approvalStatus.toLowerCase()) !== -1)
        .map(h => h.locationDetails)
        .sort((a, b) => a.name > b.name ? 1 : -1);
    },
    historyInitialised: state => state.historyInitialised
  },
  mutations: {
    setHistory(state, history) {
      const historyExt = history.map(h => {
        const displayNames = extractNames(h.approverDetUserId);
        Object.assign(h, {
          requestDescription: determineDescription(h),
          accessRequested: determineAccessRequested(h),
          isApp: isApp(h),
          approverFirstName: displayNames.firstName,
          approverLastName: displayNames.lastName
        });
        return h;
      });
      // sort in descending order of when requested
      state.history = historyExt.sort((h1, h2) => h1.requestedTimestamp > h2.requestedTimestamp ? -1 : 1);
    }
  },
  actions: {
    fetchHistory({ commit, state }) {
      if (state.historyLoading) {
        // already loading
        return;
      }

      commit('setIsLoading', true);
      state.historyLoading = true;
      historyApi.fetchHistory().then((response) => {
        commit('setHistory', response);
        commit('setIsLoading', false);
        state.historyInitialised = true;
        state.historyLoading = false;
      })
      .catch(() => {
        commit('setIsLoading', false);
        state.historyLoading = false;
      });
    }
  }
};

export default historyModule;
