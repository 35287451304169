const debounce = (func, wait, immediate) => {
  let timeout;
  return function() {
      let context = this, args = arguments;
      let later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
  };
};

const buildError = (code, message) => {
  return {
    response: {
      data: {
        errorCode: code,
        errorMessage: message
      }
    }
  }
}

export { debounce, buildError };