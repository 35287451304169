import axios from "axios";
import Vue from "vue";
import store from "../store";
import {getApiUrl} from "../utils/config";

const fetchHistory = () => {
  return axios.get(`${getApiUrl()}/UAMS/requests/staff`,
    {
      headers: Vue.prototype.$OAuth.buildHttpHeader()
    })
    .then((response) => {
      return response.data.map((history) => {
        if (history.application) {
          Object.assign(history.application, { icon: window.essentialIconCodesMap ? window.essentialIconCodesMap[history.application.code] || 'my-generic-blank' : 'my-generic-blank' });
        }
        return history;
      });
    })
    .catch((err) => {
      store.dispatch("handleApiError", err);
    });
};

export default { fetchHistory }
