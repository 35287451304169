<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <Banner
        heading="Manage My Access"
        subtitle="Select a school from the dropdown to begin"
      >
        <template slot="icon">
          <img
            src="../assets/touchApp.svg"
            alt="touch app icon"
          >
        </template>
      </Banner>
      <v-container text-center>
        <v-row>
          <v-col>
            <v-card class="text-left ma-3">
              <div class="pa-6 pb-2">
                <p class="subtitle-1">
                  Welcome to Manage My Access
                </p>
              </div>
              <v-divider />
              <div class="pa-6">
                <p>To begin, please search for a school in the dropdown menu to the top right.</p>
                <p>You will be taken to the schools landing page where you will be able to request access to applications you require for your duties. Please contact your schools administration to determine the extent of your responsibilities.</p>
                <p>
                  Accessed schools will be sorted at the top of the menu for convenience.
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div
      v-if="$vuetify.breakpoint.smAndDown"
      class="d-flex align-center justify-center"
      style="padding: 80px 54px;"
    >
      <div class="d-flex flex-column align-center justify-center">
        <img
          src="../assets/arrowUp.svg"
          alt="arrow_up"
        >
        <div class="pa-6 pb-2">
          <p class="body-2">
            Welcome to Manage My Access
          </p>
        </div>
        <div class="px-6 mb-12 pb-12">
          <p class="body-2 text-center">
            Search for or select your school from the dropdown menu above.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Banner} from "@nswdoe/doe-ui-core";

  export default {
    name: 'Welcome',
    components: {
      Banner
    }
  };
</script>
<style scoped lang="scss">
::v-deep {
  .appTitle {
    font-size: 2rem;
  }
}
</style>
