import axios from 'axios';
import Vue from 'vue';
import store from '@/store'
import { getApiUrl } from '@/utils/config';

const fetchSchools = () => {
  if (!Vue.prototype.$OAuth.isAuthenticated()) {
    return new Promise((resolve) => {
      resolve({
        userLocations: [],
        otherLocations: []
      });
    });
  }

  return axios.get(`${getApiUrl()}/UAMS/ProfileTemplate/locations`,
    {
      headers: Vue.prototype.$OAuth.buildHttpHeader()
    })
    .then((response) => response.data)
    .catch((err) => {
      store.dispatch("handleApiError", err);
      throw err;
    });
};

const submitRoleRequest = (schoolId, schoolName, roleCode, comments) => {

  const data = {
    locationID: schoolId,
    locationName: schoolName,
    requestorComments: comments || ""
  };

  return axios.put(`${getApiUrl()}/UAMS/request/role/${roleCode}`,
    data,
    {
      headers: Vue.prototype.$OAuth.buildHttpHeader()
    })
    .then((response) => response.data)
    .catch((err) => {
      store.dispatch("handleApiError", err);
      throw err;
    });
};
const clearPermissionsCache = () => {
  return axios.delete(`${getApiUrl()}/UAMS/mma/apm/clearPermissions`,
    {
      headers:  Vue.prototype.$OAuth.buildHttpHeader()
    })
    .catch((err) => {
      store.dispatch("handleApiError", err);
      throw err;
    });
};
export default { fetchSchools, submitRoleRequest, clearPermissionsCache }
