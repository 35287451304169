<template>
  <div>
    <Banner
      v-if="$vuetify.breakpoint.mdAndUp"
      :heading="selectedSchoolName"
      :subtitle="hasPendingRequest ? '' : 'Select a role at the school'"
    >
      <template slot="icon">
        <img
          src="../assets/touchApp.svg"
          alt="touch app icon"
        >
      </template>
    </Banner>
    <v-container
      text-center
      v-if="!hasPendingRequest"
    >
      <v-row>
        <v-col>
          <v-card
            id="roles--card"
            class="text-left mx-3"
          >
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <div class="pa-6">
                <h3 class="subtitle-1">
                  Welcome to {{ selectedSchoolName }}
                </h3>
              </div>
              <v-divider />
            </div>
            <div class="pa-6 pb-1">
              <div><p><b>Roles</b> contain a pre-configured set of application access permissions. If you are unsure which role is right for you, contact the school administration to determine your initial access needs.</p></div>
              <div class="mt-8">
                <span class="subtitle-1 mt-10">Please select from the options below.</span>
              </div>
            </div>
            <v-form
              ref="form"
              v-model="isValid"
            >
              <v-radio-group
                v-model="selectedRole"
                :rules="required"
              >
                <v-row class="px-6">
                  <v-col
                    cols="12"
                    md="4"
                    class="pa-0"
                  >
                    <v-card class="px-4 py-6 mx-3 my-2 role-card">
                      <v-radio
                        value="TEACHER"
                        class="align-start"
                        aria-labelledby="role-teacher-label"
                        aria-describedby="role-teacher-description"
                      >
                        <div
                          slot="label"
                          class="ml-3"
                        >
                          <div
                            class="subtitle-1"
                            id="role-teacher-label"
                          >
                            <v-icon
                              size="36"
                              class="mr-4"
                            >
                              mdi-school-outline
                            </v-icon>Teacher
                          </div>
                          <p
                            class="caption mt-3"
                            id="role-teacher-description"
                          >
                            The essential applications and tasks to perform the role of a <b>Teacher</b> at {{ selectedSchoolName }}.
                          </p>
                        </div>
                      </v-radio>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="pa-0"
                  >
                    <v-card class="px-4 py-6 mx-3 my-2 role-card">
                      <v-radio
                        value="SAM"
                        class="align-start"
                        aria-labelledby="role-admin-manager-label"
                        aria-describedby="role-admin-manager-description"
                      >
                        <div
                          slot="label"
                          class="ml-3"
                        >
                          <div
                            class="subtitle-1"
                            id="role-admin-manager-label"
                          >
                            <v-icon
                              size="36"
                              class="mr-4"
                            >
                              mdi-account-multiple-outline
                            </v-icon>Admin Manager
                          </div>
                          <p
                            class="caption mt-3"
                            id="role-admin-manager-description"
                          >
                            The essential applications and tasks to perform the role of a <b>School Administration Manager</b> at {{ selectedSchoolName }}.
                          </p>
                        </div>
                      </v-radio>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="pa-0"
                  >
                    <v-card class="px-4 py-6 mx-3 my-2 role-card">
                      <v-radio
                        value="custom"
                        class="align-start"
                        aria-labelledby="role-custom-label"
                        aria-describedby="role-custom-description"
                      >
                        <div
                          slot="label"
                          class="ml-3"
                        >
                          <div
                            class="subtitle-1"
                            id="role-custom-label"
                          >
                            <v-icon
                              size="36"
                              class="mr-4"
                            >
                              mdi-cog-outline
                            </v-icon>Individual Access
                          </div>
                          <p
                            class="caption mt-3"
                            id="role-custom-description"
                          >
                            Choose your own applications and tasks.
                          </p>
                        </div>
                      </v-radio>
                    </v-card>
                  </v-col>
                </v-row>
              </v-radio-group>
              <v-row>
                <v-col>
                  <v-textarea
                    class="mx-6"
                    v-model="comment"
                    outlined
                    name="role-request-comment"
                    placeholder=" "
                    rows="3"
                    maxlength="255"
                    counter="255"
                    :label="(this.isCustom ? 'Default comment' : 'Comment') + ' to Authoriser (Optional)'"
                    :rules="[rules.maxLength]"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <div
        class="d-flex justify-end mr-3"
        :class="{'mr-6': $vuetify.breakpoint.smAndDown, 'mt-3': $vuetify.breakpoint.mdAndUp}"
      >
        <v-btn
          v-if="!isCustom"
          :disabled="!selectedRole || submitted || !isValid || (!fnActionByName('REQUEST_ROLE') && !fnActionByName('REQUEST_APPLICATION'))"
          class="primary"
          @click="submitRequest"
        >
          <v-icon class="mr-2">
            mdi-send-outline
          </v-icon>
          Submit request
        </v-btn>
        <v-btn
          v-if="isCustom"
          class="primary"
          @click="selectApplications"
          :disabled="!isValid"
        >
          Next
          <v-icon class="ml-2">
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </div>
    </v-container>
    <v-container
      v-if="hasPendingRequest"
      class="mt-md-12"
      style="max-width: 700px"
    >
      <PendingRequestError
        :request-name="pendingRequest.isApp ? 'application' : pendingRequest.roleCode"
        :can-go-to-apps="pendingRequest.isApp"
      />
    </v-container>
  </div>
</template>

<script>
import { Banner } from "@nswdoe/doe-ui-core";
import { mapGetters } from "vuex";
import schoolsApi from "api/schools";
import PendingRequestError from "@/components/PendingRequestError";

export default {
  name: "Roles",
  components: {
    Banner,
    PendingRequestError
  },
  data() {
    return {
      selectedRole: "",
      comment: "",
      submitted: false,
      isValid: false,
      rules: {
        required: v => v != null && v != '' || "Required",
        maxLength: v => v.length <= 255 || "Max length 255 characters"
      }
    };
  },
  computed: {
    ...mapGetters({
      selectedSchoolId: 'selectedSchoolId',
      selectedSchoolName: 'selectedSchoolName',
      requestHistory: 'requestHistory',
      fnActionByName: 'getActionByName'
    }),
    isCustom() {
      return this.selectedRole === 'custom'
    },
    pendingRequest() {
      // Pending, Escalated and Approved. Approved requests are pending until they reach 's' (success) status.
      const pendingStatuses = ['p', 'e', 'a'];
      const pendingReq = this.requestHistory.find(h =>
        h.locationDetails && h.locationDetails.id == this.selectedSchoolId && (pendingStatuses.indexOf(h.approvalStatus.toLowerCase()) !== -1));

      return pendingReq;
    },
    hasPendingRequest() {
      return this.pendingRequest != null;
    }
  },
  async created() {
    if (this.selectedSchoolId == null) {
      this.$router.push('/');
    }

    this.$store.commit('setDefaultComment', '');

    if (!this.$store.getters.historyInitialised && (!this.requestHistory || this.requestHistory.length === 0)) {
      await this.$store.dispatch('fetchHistory');
    }
  },
  methods: {
    async submitRequest() {
      if (this.$refs.form.validate()) {
        this.submitted = true;
        this.$store.commit('setIsLoading', true);

        await schoolsApi.submitRoleRequest(this.selectedSchoolId, this.selectedSchoolName, this.selectedRole, this.comment)
          .then(() => {
            // reload history
            this.$store.dispatch('fetchHistory');
            this.$router.push('/')
            .then(() => {
              this.$store.commit("showSnackbar", {
                text: "Your Role access request has been sent.",
                type: "success",
                show: true,
                persistBetweenRoutes: true
              });
              this.$store.dispatch("resetToDefaultSchool");
            });
          })
          .catch(() => {
            this.submitted = false;
            this.$store.commit('setIsLoading', false);
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false);
          });
      }
    },
    selectApplications() {
      if (this.$refs.form.validate()) {
        this.$store.commit('setDefaultComment', this.comment);
        this.$router.push("/access");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  ::v-deep .role-card {
    border: 1px solid $ads-dark-60;
    box-shadow: none;
    min-height: 90%;
    .v-radio .v-input--selection-controls__input {
      margin-top: 6px;
    }
    label, label .v-icon {
      color: $ads-navy;
    }
  }
  ::v-deep {
    .appTitle {
      font-size: 2rem;
    }
  }

  #app.mobile #roles--card {
    border: none;
    box-shadow: none;
  }
</style>
