<template>
  <div v-if="loaded">
    <Banner
      v-if="$vuetify.breakpoint.mdAndUp"
      :heading="selectedSchoolName"
      icon="touch_app"
    >
      <template #icon>
        <img
          src="../assets/touchApp.svg"
          alt="touch app icon"
        >
      </template>
      <template #subtitle>
        <div class="body ml-4">
          Access to new applications, and additional access to existing ones can be requested with the <v-icon>mdi-pencil-outline</v-icon> icon
        </div>
      </template>
    </Banner>
    <v-container class="pa-0">
      <div>
        <v-card
          id="my-apps--card"
          class="ma-md-6"
          :flat="$vuetify.breakpoint.smAndDown"
          :tile="$vuetify.breakpoint.smAndDown"
        >
          <div v-if="!hasApps && fnActionByName('LIST_APPLICATIONS')">
            <div class="pl-4 pt-5 pb-2">
              <p>{{ isLoading ? "Loading..." : "No apps found." }}</p>
            </div>
          </div>
          <div v-if="hasApps">
            <AppAccessList
              v-if="birthrightApps && birthrightApps.length"
              :items="birthrightApps"
              app-heading="My Apps:"
              @appSelected="editApp"
            >
              <span
                slot="appHeadingSuffix"
                class="pl-3"
              >
                {{ roleName }}
              </span>
            </AppAccessList>
            <div
              v-else
              class="pa-3 pt-6"
            >
              <p class="notFound">
                <i>No apps found for your role{{ roleName ? " - " + roleName : "" }}.</i>
              </p>
            </div>
            <AppAccessList
              v-if="otherApps && otherApps.length"
              :items="otherApps"
              app-heading="Other apps"
              @appSelected="editApp"
            />
          </div>
        </v-card>
      </div>
    </v-container>
    <v-dialog
      v-if="showSelectedAppTaskModal"
      v-model="showSelectedAppTaskModal"
      max-width="650"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <AppTaskList
        @close="showSelectedAppTaskModal = false"
        :app-id="selectedApp.applicationId"
        :app-icon="selectedApp.icon"
        :app-desc="selectedApp.applicationDesc"
        :tasks="selectedApp.tasks"
        :default-comment="isUserSchoolSelected ? '' : defaultComment"
      />
    </v-dialog>
  </div>
</template>

<script>
import {Banner} from "@nswdoe/doe-ui-core";
import AppAccessList from "@/components/AppAccessList";
import AppTaskList from "@/components/AppTaskList";

import {searchDebounce} from '@/utils/filters';
import {mapGetters} from "vuex";
import capitalise from "@/filters/capitalise";

export default {
  components: {
    Banner,
    AppAccessList,
    AppTaskList
  },
  data() {
    return {
      loaded: false,
      selectedApp: {},
      showSelectedAppTaskModal: false
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      selectedSchoolId: 'selectedSchoolId',
      selectedSchoolName: 'selectedSchoolName',
      isUserSchoolSelected: 'isUserSchoolSelected',
      defaultComment: 'defaultComment',
      requestHistory: 'requestHistory',
      fnActionByName: 'getActionByName'
    }),
    roleName() {
      const role = this.$store.getters.roleBySchool(this.selectedSchoolId);
      let roleDesc = capitalise(role);
      if (role.toLowerCase() === 'sam') {
        roleDesc = 'Admin Manager';
      }
      return roleDesc;
    },
    allApps() {
      const pendingApps = this.pendingRequests.filter(p => p.application != null);
      const myApps = this.$store.getters.applicationsBySchool(this.selectedSchoolId);

      for (const a of myApps) {
        // get all pending requests for this app
        const pendingRequests = pendingApps.filter(p => p.application.id === a.applicationId);

        // get all the requested task ids
        const pendingTaskIds = pendingRequests.reduce((acc, pa) => { return acc.concat(pa.tasks.map(pat => pat.id)) }, []);

        // mark applicable task ids as pending
        for (const t of a.tasks) {
          if (pendingTaskIds.indexOf(t.taskId) !== -1) {
            t.accessRequested = true;
          }
        }
      }

      return myApps;
    },
    birthrightApps() {
      return this.allApps.filter(a => a.tasks.some(t => t.birthright));
    },
    otherApps() {
      return this.allApps.filter(a => a.tasks.every(t => !t.birthright));
    },
    hasApps() {
      return this.allApps && this.allApps.length > 0;
    },
    pendingRequests() {
      // Pending, Escalated and Approved. Approved requests are pending until they reach 's' (success) status.
      const pendingStatuses = ['p', 'e', 'a'];
      return this.requestHistory.filter(h =>
        h.locationDetails && h.locationDetails.id === this.selectedSchoolId && (pendingStatuses.indexOf(h.approvalStatus.toLowerCase()) !== -1));
    }
  },
  watch: {
    selectedSchoolId(schoolId) {
      if (schoolId != null) {
        if (!this.checkPendingRoleRequest()) {
          return;
        }
        this.$store.dispatch('fetchApplications', {schoolId: this.selectedSchoolId});
      }
    }
  },
  async created() {
    if (!this.checkPendingRoleRequest()) {
      return;
    }

    const promises = [];
    if (!this.allApps.length && this.selectedSchoolId != null) {
      promises.push(this.$store.dispatch('fetchApplications', {schoolId: this.selectedSchoolId}));
    }

    if (!this.$store.getters.historyInitialised && (!this.requestHistory || this.requestHistory.length === 0)) {
      promises.push(this.$store.dispatch('fetchHistory'));
    }

    await Promise.all(promises);

    this.loaded = true;
  },
  methods: {
    ...searchDebounce, // import search methods
    editApp(app) {
      this.selectedApp = app;
      this.showSelectedAppTaskModal = true;
    },
    checkPendingRoleRequest() {
      const pendingRoleRequest = this.pendingRequests.find(p => !p.application && p.roleCode !== 'INDIVIDUAL');
      // kick them out if they have a pending role request for this other school
      if (!this.isUserSchoolSelected && pendingRoleRequest != null) {
        this.$router.push('/roles');
        return false;
      }
      return true;
    }
  }
}
</script>

<style scoped lang="scss">
  .notFound {
    color: $ads-light-40;
  }
  ::v-deep {
    .appTitle {
      font-size: 2rem;
    }
  }
</style>
