import axios from 'axios';

// Why not just put it in .env? Because the .env variables are statically compiled into the app.
// We need to get the env variables from the serving openshift container e.g. test/pre/prod since we don't re-build per environment

const Env = {
  install: function (Vue) {
    Vue.prototype.$env = {
      ...process.env,
      init(fetchEnvUrl) {
        return axios.get(fetchEnvUrl)
          .then((response) => {
            Object.assign(this, response.data.env);
          })
          .catch(err => {
            // eslint ignore next line
            console.error("Could not initialise environment variables from", fetchEnvUrl, err);
          });
      },
      get(param, defaultValue) {
        return (this[param] != null) ? this[param] : defaultValue;
      }
    }
  }
}

export default Env;
